function assignFlatpickrWatchers(parent) {

    /**
     * Define date field Flatpickrs.
     */
    $(parent + ' .sre-flatpickr-date').flatpickr({
        allowInput: true
    });

    /**
     * Define time field Flatpickrs.
     */
    $(parent + ' .sre-flatpickr-time').flatpickr({
        allowInput: true,
        dateFormat: 'H:i:S',
        enableTime: true,
        noCalendar: true
    });

}

$(function() {

    /**
     * Assign Flatpickr watchers on load.
     */
    assignFlatpickrWatchers('#eventtimes');

    /**
     * Unique ID for newly added EventTimes.
     */
    var eventtimeCounter = 0;

    /**
     * Adds an EventTime attribute to an event form.
     */
    $('.action-add-eventtime').click(function(event) {
        eventtimeCounter++;

        var template = $('#eventtime-template').html();
        var info = Mustache.to_html(template, {
            'id': eventtimeCounter
        });
        $('#eventtimes').append(info);

        assignFlatpickrWatchers('#eventtime-new-' + eventtimeCounter);
    });

    /**
     * Removes an EventTime attribute from an event form.
     */
    $(document.body).on('click', '.action-remove-eventtime', function(event) {
        var id = $(event.target).data('id');
        $('#eventtime-' + id).remove();
    });

});
