$(function() {

    /**
     * Handle "Change Password" button clicks.
     */
    $('.srr-action-change-password').click(function(event) {
        event.preventDefault();

        var password_field = $('#field-password');

        password_field.prop('placeholder', '');
        password_field.prop('disabled', false);
        password_field.focus();
    });

});
