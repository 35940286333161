$(function() {

    /**
     * Handles logout.
     */
    $('.srr-action-logout').click(function(event) {
        event.preventDefault();
        document.getElementById('srr-form-logout').submit();
    });

});
