$(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    /**
     * Toggles the like-state of the event.
     */
    $(document.body).on('click', '.srr-action-toggle-like', function(event) {
        event.preventDefault();
        var uuid = $(event.target).parents('.srr-like-button').data('id');

        $.ajax({
            method: 'POST',
            url: '/events/'+uuid+'/like'
        })
        .done(function(msg) {
            var response = jQuery.parseJSON(msg);
            var button_elem = $('.srr-like-button[data-id="'+response.event+'"]');
            var link_elem = $('.srr-like-button[data-id="'+response.event+'"] a');

            if (response.status == 'liked') {
                button_elem.addClass('srr-status-liked');
                link_elem.text('Unlike');
            } else {
                button_elem.removeClass('srr-status-liked');
                link_elem.text('Like');
            }
        });
    });

});
