$(function() {

    /**
     * Track outbound links.
     */
    $('.srr-action-outbound-link').click(function(event) {
        if (typeof ga === 'undefined') {
            return;
        }

        ga('send', 'event', {
            eventCategory: 'Outbound Link',
            eventAction: 'click',
            eventLabel: event.target.href,
            transport: 'beacon'
        });
    });

});
