$(function() {

    $('#srr-toggle-action-featured').click(function(event) {
        event.preventDefault();
        $('#srr-toggle-pane-featured').tab('show');
    });

    $('.srr-nav-link-toggle-all').click(function(event) {
        event.preventDefault();

        var pane = $('#srr-toggle-pane-all');

        if (pane.data('loaded') != '1') {
            $.get('/events/snippet.html', function(data) {
                pane.html(data);
                pane.data('loaded', '1');
            });
        }

        pane.tab('show');
    });

});
