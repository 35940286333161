$(function() {

    /**
     * Display photo if image data is available.
     */
    var elem = $('.srr-photo');
    var uuid = elem.data('uuid');
    var path = 'url(/img/pages/'+uuid+'.jpg)';

    elem.css('background-image', path);

});
