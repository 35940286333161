$(function() {

    if (typeof fullCalendarEvents !== 'undefined') {
        $('#calendar').fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay'
            },
            eventLimit: true,
            events: fullCalendarEvents
        });
    }

});
