$(function() {

    /**
     * Initialize and display map if location data is available.
     */
    if (document.getElementById('map')) {
        var location = '';
        var lat = null;
        var lon = null;

        if ($('#map').data('mapaddress1')) {
            location += $('#map').data('mapaddress1');
        }
        if ($('#map').data('mapaddress2')) {
            location += ' ' + $('#map').data('mapaddress2');
        }
        if ($('#map').data('mapcity')) {
            location += ' ' + $('#map').data('mapcity');
        }
        if ($('#map').data('mapstate')) {
            location += ' ' + $('#map').data('mapstate');
        }
        if ($('#map').data('mapzip')) {
            location += ' ' + $('#map').data('mapzip');
        }

        if ($('#map').data('maplat')) {
            lat = ' ' + $('#map').data('maplat');
        }
        if ($('#map').data('maplon')) {
            lon = ' ' + $('#map').data('maplon');
        }

        L.mapbox.accessToken = 'pk.eyJ1Ijoid2FsdCIsImEiOiJFQTFLYWRBIn0.14z66keKuNqMpy_Add5uMQ';
        var map = null;

        if (lat && lon) {
            updateMap(lat, lon);
            return true;
        }

        var geocoder = L.mapbox.geocoder('mapbox.places');
        geocoder.query(location, function (err, data) {
            if (data.latlng) {
                updateMap(data.latlng[0], data.latlng[1]);
            } else if (data.lbounds) {
                map = L.mapbox.map('map', 'mapbox.streets').fitBounds(data.lbounds);
            }
        });
    }

    function updateMap(lat, lon) {
        map = L.mapbox.map('map', 'mapbox.streets').setView([lat, lon], 13);

        L.mapbox.featureLayer({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [lon, lat]
            },
            properties: {
                'marker-color': '#090',
                'marker-size': 'small'
            }
        }).addTo(map);
    }

});
