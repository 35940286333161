$(function() {

    /**
     * Translate the value of the name field into a shortname.
     */
    $('.shortnameify').click(function(event) {
        slug.defaults.mode = 'rfc3986';
        $('#field-shortname').val(slug($('#field-name').val()));
    });

});
